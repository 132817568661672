.copyright {
  display: flex;
  font-weight: 500;

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 80px;
    padding: 10px 15px;
    border-bottom: 1px solid var(--footer-border-color);
  }
}

.text {
  color: var(--gray-dark);

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    text-align: center;

    &:first-child {
      margin-bottom: 5px;
    }
  }

  @media screen and (min-width: 1280px) {
    &:first-child {
      margin-right: 40px;
    }
  }

  font-size: 0.875rem;
  line-height: 1.0625rem;
}