.social {
  display: flex;

  &Link {
    display: flex;
    fill: var(--black);

    &:hover {
      path {
        fill: var(--header-select-color); // TODO: update CMS with --footer-select-color var. tmp fix
      }
    }

    @media screen and (min-width: 1280px) {
      margin-bottom: auto;
    }

    @media screen and (min-width: 279px) and (max-width: 1279px) {
      margin-right: 25px;

      &:last-child {
        margin-right: 0;
      }
    }

    @media screen and (min-width: 1280px) {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.service {
  display: flex;

  @media screen and (min-width: 1280px) {
    flex-direction: column;
    width: 255px;
    flex-shrink: 0;
    margin-right: 40px;
  }

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    border-top: 1px solid var(--footer-border-color);
  }

  p {
    font-size: 0.875rem;
    line-height: 1.0625rem;
    font-weight: 500;
    color: var(--footer-primary-text-color);

    @media screen and (min-width: 279px) and (max-width: 1279px) {
      margin-right: 35px;
    }

    @media screen and (min-width: 1280px) {
      margin-bottom: 15px;
    }
  }

  svg {
    path {
      fill: var(--footer-primary-text-color);
      transition: var(--transition);
    }
  }
}
