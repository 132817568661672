.mobile {
  @media screen and (min-width: 768px) {
    display: none !important;
    visibility: hidden !important;
  }
}

.tablet {
  @media screen and (min-width: 1024px) {
    display: none !important;
    visibility: hidden !important;
  }
}

.bigTablet {
  @media screen and (max-width: 1023px) {
    display: none !important;
    visibility: hidden !important;
  }
}

.desktop {
  @media screen and (max-width: 1279px) {
    display: none !important;
    visibility: hidden !important;
  }
}

.notMobile {
  @media screen and (max-width: 767px) {
    display: none !important;
    visibility: hidden !important;
  }
}

.notTablet {
  @media screen and (max-width: 1023px) {
    display: none !important;
    visibility: hidden !important;
  }
}

.notBigTablet {
  @media screen and (min-width: 1279px) {
    display: none !important;
    visibility: hidden !important;
  }
}

.notDesktop {
  @media screen and (min-width: 1280px) {
    display: none !important;
    visibility: hidden !important;
  }
}