.sort {
  &Container {
    display: flex;
    height: 100%;
    transition: var(--transition);
  }

  &Content {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 320px;
    min-width: 320px;
    height: 100%;
    background-color: var(--gray-light);
    border-right: 1px solid var(--gray-text);
    overflow-y: auto;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px 25px;
      border-bottom: 1px solid var(--gray-text);

      &.active {
        font-weight: bold;
      }
    }
  }
}