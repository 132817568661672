.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.clearAll {
  display: flex;
  align-items: center;
  height: 32px;
  margin-bottom: 10px;
  padding: 0 15px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 0.8125rem;
  color: var(--primary);
  background-color: transparent;
  border: none;
  outline: none;
  transition: var(--transition);

  &:hover {
    color: var(--primary-dark);
  }
}

.item {
  margin-right: 10px;
  margin-bottom: 10px;

  &:last-child {
    margin-right: 0;
  }
}
