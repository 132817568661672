.container {
  display: flex;
  width: 100%;
  border-bottom: 1px solid var(--gray-text);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    flex-direction: column;
  }

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    border-top: 1px solid var(--gray-text);
  }
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 35px;
}

.title {
  max-width: 300px;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  color: var(--black);

  &.search {
    margin-top: 60px;
    margin-bottom: 10px;

    @media screen and (min-width: 279px) and (max-width: 767px) {
      order: -1;
      margin-top: 10px;
      margin-bottom: 30px;
    }
  }
}

.image {
  max-width: 420px;
  width: 100%;
  object-fit: contain;
}
