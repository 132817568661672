.container {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 5px 10px 5px 15px;
  border: 1px solid var(--gray-text);
  border-radius: 5px;
  cursor: pointer;
}

.name,
.value {
  display: flex;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: var(--black);
}

.value {
  margin-left: 5px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 50%;
  transition: var(--transition);

  path {
    stroke: var(--gray-dark);
  }

  &:hover {
    background-color: var(--gray-text);
  }
}
