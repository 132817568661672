.container {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: var(--header-color);
  z-index: 4;
  transition: var(--transition);

  & + main,
  & + section {
    @media screen and (min-width: 279px) and (max-width: 1023px) {
      min-height: calc(100vh - 102px);
    }

    @media screen and (min-width: 1024px) {
      min-height: calc(100vh - 121px);
    }
  }

  & + div {
    @media screen and (min-width: 279px) and (max-width: 1023px) {
      top: 102px;
    }

    @media screen and (min-width: 1024px) {
      top: 121px;
    }
  }

  & + section {
    & > div:first-of-type {
      height: 100% !important;
    }
  }
}

.footer {
  background-color: var(--footer-color);

  &Border {
    border-top: 1px solid var(--footer-border-color);
  }
}

.footerMain {
  &Container {
    display: flex;
    background-color: var(--footer-color);

    @media screen and (min-width: 279px) and (max-width: 1279px) {
      flex-direction: column-reverse;
      padding: 0;
    }

    @media screen and (min-width: 1280px) {
      padding: 40px 25px;
    }
  }
}