.container {
  @media screen and (min-width: 279px) and (max-width: 1279px) {
    padding: 20px 15px 0;
  }

  @media screen and (min-width: 1280px) {
    padding: 30px 25px 10px;
    border-bottom: 1px solid var(--gray-text);
  }
  z-index: 2;
}

.header {
  display: flex;

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    align-items: baseline;
    margin-bottom: 15px;
  }

  @media screen and (min-width: 1280px) {
    align-items: center;
    margin-bottom: 20px;
  }
}

.title {
  margin-right: 5px;
  font-weight: bold;

  color: var(--black);

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  @media screen and (min-width: 1280px) {
    font-size: 1.625rem;
    line-height: 1.875rem;
  }
}

.count {
  display: flex;
  color: var(--gray-secondary);

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }

  @media screen and (min-width: 1280px) {
    font-size: 1.25rem;
    line-height: 1rem;
  }
}

.actions {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.button {
  display: flex;
  background-color: transparent;
  border: none;
  outline: none;
}

.filterButton,
.sortButton {
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  svg {
    color: var(--black);
  }
}
