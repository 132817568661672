.compare {
  position: relative;
  svg {
    line,
    path {
      transition: var(--transition);
    }
    color: var(--header-text-color);
  }

  &:hover {
    svg {
      line {
        stroke: var(--primary);
      }

      path {
        fill: var(--primary);
      }
      color: var(--header-text-color);
    }
  }

  &Count {
    position: absolute;
    top: -6px;
    right: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    font-size: 0.6875rem;
    line-height: 0.5rem;
    background-color: var(--primary);
    color: var(--white);
    border-radius: 50%;
    transition: var(--transition);
  }
}