.container {
  position: relative;
  max-width: 400px;
  width: 100%;
  margin-right: 15px;

  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    max-width: 320px;
  }
}

.label {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  visibility: hidden;
}

.input {
  display: flex;
  width: 100%;
  height: 38px;
  margin-right: 20px;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: var(--black);
  border: none;
  outline: none;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    font-size: 16px;
  }

  &Container {
    display: flex;
    align-items: center;
    padding-left: 20px;
    border: 1px solid var(--gray-text);
    transition: var(--transition);

    &:focus,
    &:active,
    &:hover,
    &.opened {
      border-color: var(--gray-secondary);
    }
  }
}

.searchIcon {
  flex-shrink: 0;
  margin-right: 10px;
  color: var(--gray-text);
}

.showResultsButton {
  display: none;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  padding: 0 15px;
  height: 38px;
  pointer-events: none;

  @keyframes opacityKeyframe {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  &.active {
    display: flex;
    animation: opacityKeyframe 0.2s ease-out;
    animation-iteration-count: 1;
    pointer-events: inherit;
  }

  svg {
    flex-shrink: 0;
    path {
      stroke: var(--white);
    }
  }

  &:hover {
    &:before {
      border-color: var(--primary-dark);
    }
  }

  &:before {
    position: absolute;
    top: -2px;
    right: -2px;
    content: "";
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border: 1px solid var(--primary);
    transition: var(--transition);
  }
}
