.footerAdditional {
  background-color: var(--footer-map-color);

  &Container {
    display: flex;

    @media screen and (min-width: 279px) and (max-width: 1279px) {
      flex-direction: column;
    }

    @media screen and (min-width: 1280px) {
      align-items: center;
      justify-content: space-between;
      height: 40px;
      padding: 0 25px;
      align-items: center;
    }
  }
}

.footerLogo {
  display: flex;
  height: 1.125rem;

  img {
    height: 100%;
  }

  &Text {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--white);
  }

  &Container {
    display: flex;
    align-items: center;

    @media screen and (min-width: 279px) and (max-width: 1279px) {
      align-items: center;
      justify-content: center;
      height: 65px;
    }
  }
}

.link {
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: var(--gray-secondary);
  transition: var(--transition);
  font-weight: 500;

  &:hover {
    color: var(--white);
  }
}

.links {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &Link {
    display: flex;
    flex: 1 1 50%;
    align-items: center;
    justify-content: center;
    height: 80px;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: var(--white);
    border-bottom: 1px solid var(--footer-border-color);

    &:first-child {
      border-right: 1px solid var(--footer-border-color);
    }
  }
}
