.submenuContainer {
  position: absolute;
  bottom: 0;
  left: -12.5px;
  display: none;
  transform: translateY(100%);
  z-index: 5;

  &.active {
    display: flex;
  }

  &.newModels {
    @media screen and (max-width: 1919px) {
      max-height: 560px;
    }

    @media screen and (min-width: 1920px) {
      max-height: 540px;

      .subcategory {
        padding-right: 32px;
      }
    }
  }
}

.shopMenu {
  display: flex;
  background-color: var(--next-level-menu-color);
  border-right: 1px solid var(--header-border-color);
  border-bottom: 1px solid var(--header-border-color);
  overflow: hidden;

  @media screen and (max-width: 1919px) {
    padding-left: 24px;
    padding-right: 38px;
  }

  @media screen and (min-width: 1920px) {
    padding: 26px 38px 32px 24px;
  }

  &Container {
    display: grid;
    width: 100%;

    gap: 24px 12.9px;

    @media screen and (max-width: 1919px) {
      grid-template-columns: repeat(3, 155px);
      padding: 26px 0;
    }

    @media screen and (min-width: 1920px) {
      grid-template-columns: repeat(4, 155px);
      padding: 26px 0 32px;
    }
  }
}

.navigation {
  display: flex;
  flex: 1;
  gap: 24px;
  height: 100%;
  margin: 0 24px;
  justify-content: space-evenly;
}

.link {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 12.5px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25;
  color: var(--header-text-color);
  background-color: transparent;
  transition: var(--transition);

  @media screen and (min-width: 1920px) {
    font-size: 1.3125rem;
    line-height: 1.25rem;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &:before {
    position: absolute;
    bottom: 0px;
    left: 9px;
    content: "";
    width: calc(100% - 25px);
    height: 2px;
    background-color: transparent;
    transition: var(--transition);
    z-index: 6;
  }

  &:hover {
    color: var(--header-select-color);
  }

  &__active {
    &:before {
      background-color: var(--header-select-color);
    }
  }

  &Container {
    position: relative;
    height: 100%;
  }
}

.subcategory {
  display: flex;
  flex-direction: column;
  width: max-content;
  padding: 26px;
  gap: 18px;
  background-color: var(--next-level-menu-color);
  border: 1px solid var(--header-border-color);
  border-top: none;
  transition: var(--transition);

  &Btn {
    background-color: transparent !important;
    color: var(--header-select-color);
    border-color: var(--header-select-color);
    transition: var(--transition);
    height: inherit !important;
    min-height: 40px;
    width: fit-content !important;
    min-width: 200px;
    padding: 0 24px;

    &:hover {
      background-color: var(--header-select-color) !important;
      border-color: var(--header-select-color) !important;
    }
  }

  &Item {
    display: flex;
    position: relative;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }

  &Link {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 6;

    &:hover {
      .subcategoryLinkText {
        color: var(--header-select-color);
      }
    }

    &Active {
      &:before {
        position: absolute;
        bottom: -10px;
        left: 0;
        content: "";
        width: 100%;
        height: 2px;
        background-color: var(--header-select-color);
        transition: var(--transition);
        z-index: 1;
      }
    }

    &Text {
      font-size: 1rem;
      line-height: 1.25rem;
      white-space: nowrap;
      color: var(--header-text-color);
      transition: var(--transition);
    }

    &Image {
      object-fit: contain;
      max-height: 47px;
      max-width: 95px;
      width: 100%;
      height: 100%;
    }

    &WithImage {
      height: 70px;
      flex-direction: column;
      justify-content: center;

      .subcategoryLinkText {
        margin-top: 5px;
        font-size: 0.75rem;
        line-height: 0.9375rem;
      }
    }
  }
}

.slider {
  &Link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    height: 102px;
    width: 100%;
    transition: var(--transition);
    cursor: pointer;

    &:hover {
      p {
        color: var(--header-select-color);
      }

      .sliderTitleCount {
        background-color: var(--header-select-color);
      }
    }
  }

  &Title {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: var(--header-text-color);

    p {
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      transition: var(--transition);
    }

    &Count {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      line-height: 1.0625rem;
      width: 17px;
      height: 17px;
      flex-shrink: 0;
      margin-top: 0;
      border-radius: 50%;
      background-color: rgb(from var(--header-select-color) r g b / 50%);
      color: var(--white);
      transition: var(--transition);
    }

    &Selected,
    &:hover {
      p {
        color: var(--primary);
      }

      .sliderTitleCount {
        background-color: var(--header-select-color);
      }
    }
  }
}

.modelsImage {
  object-fit: contain;
}
