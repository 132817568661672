.links {
  display: grid;
  gap: 25px;
  width: 100%;

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    padding: 25px 15px 30px;
  }

  @media screen and (min-width: 279px) and (max-width: 499px) {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  @media screen and (min-width: 500px) and (max-width: 767px) {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  @media screen and (min-width: 768px) and (max-width: 1279px) {
    grid-template-columns: repeat(6, 1fr);
  }

  &Container {
    display: flex;
    flex-direction: column;
  }
}

.title,
.link {
  margin-bottom: 14px;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  transition: var(--transition);
}

.link {
  color: var(--footer-secondary-text-color);

  &:last-child {
    margin-bottom: 0;
  }

  &.title {
    font-weight: 500;
    color: var(--footer-primary-text-color);
  }

  &:hover {
    color: var(--header-select-color); // TODO: update CMS with --footer-select-color var. tmp fix
  }
}
