.language {
  display: flex;
  align-items: center;
  margin-top: auto;

  &Item {
    margin-right: 7px;
    border: none;
    background-color: transparent;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: var(--footer-secondary-text-color);
    transition: var(--transition);

    &.active {
      color: var(--footer-primary-text-color);
    }

    &:hover {
      color: var(--header-select-color); // TODO: update CMS with --footer-select-color var. tmp fix
    }
  }
}
