.dropdown {
  width: 202px !important;

  &Label {
    display: flex;
    margin-right: 15px;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: var(--black);
  }

  &Container {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}