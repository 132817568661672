.days {
  @media screen and (min-width: 500px) and (max-width: 1023px) {
    margin-right: 24px;
  }

  @media screen and (min-width: 1024px) {
    margin-right: 60px;
  }
}

.additionalInfo {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--header-additional-color);
  z-index: 4;

  &.hidden {
    opacity: 0;
  }

  @media screen and (max-width: 1024px) {
    justify-content: space-between;
    height: 41px;
  }

  @media screen and (min-width: 1025px) {
    justify-content: flex-end;
  }

  @media screen and (min-width: 500px) and (max-width: 1023px) {
    padding: 0 16px;
  }

  @media screen and (min-width: 1024px) {
    padding: 0 24px;
  }

  &:not(&.centerMenu) {
    height: 41px;
  }
}

.logo {
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;

  img {
    max-height: 55px;
    height: 100%;
  }
}

.centerMenu {
  border-bottom: 1px solid var(--header-border-color);

  @media screen and (min-width: 1024px) {
    position: relative;
    display: flex;
    align-items: center;
    height: 71px;
  }
}

.social {
  display: flex;
  align-items: center;
  margin-right: auto;

  &Link {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      path {
        fill: var(--header-additional-text-color);
        transition: var(--transition);
      }
    }

    @media screen and (min-width: 279px) and (max-width: 1279px) {
      margin-right: 25px;

      &:last-child {
        margin-right: 0;
      }
    }

    @media screen and (min-width: 1280px) {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
