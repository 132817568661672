/* SimpleBar.css */
.simplebar-container > .simplebar-track .simplebar-scrollbar:before {
  background-color: rgba(99, 99, 130, 0.2);
}

.simplebar-container > .simplebar-track .simplebar-visible:before {
  opacity: 0.2;
}

.simplebar-container > .simplebar-track.simplebar-vertical {
  width: 4px;
  right: 0;
  pointer-events: inherit;
  border-radius: 2px;
}

.simplebar-container.trackoffset-small .simplebar-track.simplebar-vertical {
  right: -12px;
}

.simplebar-container.trackoffset-medium .simplebar-track.simplebar-vertical {
  right: -24px;
}

.simplebar-container > .simplebar-track.simplebar-vertical:hover,
.simplebar-container > .simplebar-track.simplebar-vertical:active,
.simplebar-container > .simplebar-track.simplebar-vertical:focus,
.simplebar-container > .simplebar-track.simplebar-vertical:focus-within {
  width: 8px;
  transition: var(--transition);
}

.simplebar-container > .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  width: 8px;
  opacity: 1;
  transition: var(--transition);
  right: 0;
  left: 0;
  bottom: 4px;
  top: 4px;
  cursor: pointer;
}

.simplebar-container > .simplebar-track.simplebar-horizontal {
  bottom: 8px;
  height: 4px;
  pointer-events: inherit;
}

.simplebar-container > .simplebar-track.simplebar-horizontal:hover,
.simplebar-container > .simplebar-track.simplebar-horizontal:active,
.simplebar-container > .simplebar-track.simplebar-horizontal:focus,
.simplebar-container > .simplebar-track.simplebar-horizontal:focus-within {
  height: 8px;
  transition: var(--transition);
}

.simplebar-container > .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 8px;
  opacity: 0.3;
  transition: var(--transition);
  right: 4px;
  left: 4px;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.simplebar-scrollbar:before {
  background: rgba(99, 99, 130, 0.3);
  transition: var(--transition);
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: 1;
}

.simplebar-scrollbar.simplebar-visible.simplebar-hover:before {
  background: rgba(99, 99, 130, 0.5);
}

.simplebar-scrollbar {
  cursor: pointer;
}
