.activeOption,
.option,
.phone {
  @media screen and (min-width: 279px) and (max-width: 499px) {
    grid-template-columns: auto minmax(70px, 1fr);
  }

  @media screen and (min-width: 500px) {
    grid-template-columns: auto minmax(100px, 1fr);
  }
}

.phone {
  @media screen and (min-width: 279px) and (max-width: 375px) {
    grid-template-columns: auto minmax(55px, 1fr) !important;
  }
}

.activeOption,
.option {
  display: grid;
  align-items: center;
  cursor: pointer;
  gap: 10px;

  &:hover {
    .value,
    .text {
      color: var(--white);
    }
  }

  .value {
    text-transform: capitalize;
  }
}

.value,
.text {
  pointer-events: none;
}

.activeOption {
  position: relative;
  padding-left: 15px;
  padding-right: 30px;
  height: 40px;
  background-color: var(--header-additional-color);

  svg {
    position: absolute;
    right: 0;
    display: flex;
    margin-left: 5px;
    margin-right: 5px;

    path {
      stroke: var(--header-additional-text-color);
      transition: var(--transition);
    }
  }

  &:hover {
    svg {
      path {
        stroke: var(--white);
      }
    }
  }

  &.label {
    display: flex;
    justify-content: flex-end;
  }

  & + .phone,
  & + .option {
    padding-top: 10px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  align-self: baseline;
  z-index: 4;

  &.centerMenu {
    @media screen and (min-width: 1025px) {
      transform: translateY(15px);
    }
  }

  &.active {
    background-color: var(--header-additional-color);
  }
}

.value,
.text {
  display: flex;
  font-weight: 500;
  line-height: 0.9375rem;
  white-space: nowrap;
  color: var(--header-additional-text-color);
  transition: var(--transition);

  @media screen and (min-width: 279px) and (max-width: 399px) {
    font-size: 12px;
  }

  @media screen and (min-width: 400px) and (max-width: 1023px) {
    font-size: 0.75rem;
  }

  @media screen and (min-width: 1024px) {
    font-size: 0.875rem;
  }
}

.text {
  justify-self: flex-end;
}

.option {
  padding-right: 30px;
  padding-left: 15px;
  padding-bottom: 10px;
}

.phone,
.option {
  &:not(&.active) {
    height: 0;
    padding-bottom: 0;
    padding-top: 0;
    overflow: hidden;
  }

  &:last-of-type {
    padding-bottom: 15px;
  }
}

.phone {
  display: grid;
  align-items: center;
  width: 100%;
  padding-left: 15px;
  padding-right: 30px;
  padding-bottom: 10px;
  gap: 10px;

  @media screen and (min-width: 279px) and (max-width: 350px) {
    padding-right: 10px;
    padding-left: 10px;
  }
}
